<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="110px" size="mini" :rules="rules">
            <el-form-item label="题目：" prop="questionTitle">
                <el-input v-model="form.questionTitle"   type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}"/>
            </el-form-item>
            <el-form-item label="题目解释：" prop="titleExplain">
                <el-input v-model="form.titleExplain" type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}"/>
            </el-form-item>
            <el-form-item label="题目类型" prop="titleType">
                <el-select v-model="form.titleType" placeholder="" style="width: 100%">
                    <el-option label="单选" value="1"></el-option>
                    <el-option label="多选" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                    v-for="(items, index) in form.answerOption"
                    :label="'答案选项' + (index+1)"
                    :key="index"
                    :prop="'answerOption.' + index + '.AnswerTxt'"
                    :rules="{ required: true, message: '不能为空', trigger: 'blur'}"
            >
                <el-row :gutter="10">
                    <el-col :span="8">
                        <el-input v-model="items.AnswerTxt"></el-input>
                    </el-col>
                    <el-col :span="7">
                        <el-input v-model="items.value"></el-input>
                    </el-col>
                    <el-col :span="8">
                        <el-button @click.prevent="handelRemove(items)" v-if="index>0">删除</el-button>
                        <el-button @click.prevent="handelAdd()" v-if="index == form.answerOption.length-1">添加</el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {
                    answerOption:[]
                }
            },
            title: {
                default: "添加/修改"
            },
        },
        data() {
            return {
                innerVisible: false,
                roleList:[],
                rules: {
                    questionTitle: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    titleType: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            handelRemove(item) {
                var index = this.form.answerOption.indexOf(item)
                if (index !== -1) {
                    this.form.answerOption.splice(index, 1)
                }
            },
            handelAdd() {
                this.form.answerOption.push({
                    AnswerOption: '',
                    AnswerTxt: ""
                });
            },
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                try {
                    let url = `/wenjuan`
                    const resp = await this.$http.post(url, this.form)
                    this.$message[resp.data.status](resp.data.msg)
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
